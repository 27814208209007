body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #282a36;
  color: #f8f8f2;
}

main {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

button {
  background-color: #6272a4;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #8c94b9;
}
